define("dancecloud-dancer-app/modifiers/scroll-calendar-tabs", ["exports", "ui-dancecloud-ember/modifiers/scroll-calendar-tabs"], function (_exports, _scrollCalendarTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scrollCalendarTabs.default;
    }
  });
});