define("dancecloud-dancer-app/pods/my/tickets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V7gsz1tw",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"Tickets\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n    Tickets for \"],[10,\"strong\"],[12],[2,\"upcoming events\"],[13],[2,\" that are in your name\\n    are shown below. If you purchased a ticket for someone else, you\\n    can view purchase details on your\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"my.orders\"]],[[\"default\"],[{\"statements\":[[2,\"Orders page.\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"table-tickets\",[],[[\"@tickets\"],[[30,[36,0],[\"danceEvent.startsAt\",[32,1]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sort-by\"]}",
    "moduleName": "dancecloud-dancer-app/pods/my/tickets/template.hbs"
  });

  _exports.default = _default;
});