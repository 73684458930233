define("dancecloud-dancer-app/pods/components/layout-applications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b7mQGWOh",
    "block": "{\"symbols\":[\"@applications\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[8,\"sweet-alert\",[],[[\"@show\",\"@title\",\"@text\",\"@showCancelButton\",\"@onConfirm\",\"@willClose\"],[[32,0,[\"confirmDelete\"]],\"Leave Wait List\",[30,[36,0],[\"Are you sure you want to leave the wait list for \",[32,0,[\"selectedApplicant\",\"danceEvent\",\"fullTitle\"]],\"?\"],null],true,[30,[36,1],[[32,0,[\"doDelete\"]],[32,0,[\"selectedApplicant\"]]],null],[30,[36,1],[[32,0,[\"setConfirmDelete\"]],false],null]]],null],[2,\"\\n\\n  \"],[10,\"h1\"],[12],[2,\"Wait Lists\"],[13],[2,\"\\n\\n  \"],[8,\"layout-applications/applications\",[],[[\"@applications\",\"@onDelete\"],[[32,1],[32,0,[\"selectApplicant\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"fn\"]}",
    "moduleName": "dancecloud-dancer-app/pods/components/layout-applications/template.hbs"
  });

  _exports.default = _default;
});