define("dancecloud-dancer-app/utils/configure-sentry-scope", ["exports", "@sentry/ember"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = configureSentryScope;

  function configureSentryScope(user) {
    if (user) {
      Ember.debug("Configuring Sentry scope for user ".concat(user.id, "."));
      Sentry.configureScope(function (scope) {
        scope.setUser({
          id: user.id
        });
      });
    } else {
      Ember.debug('No user to configure Sentry scope with.');
    }
  }
});