define("dancecloud-dancer-app/helpers/group-by-days", ["exports", "ui-dancecloud-ember/helpers/group-by-days"], function (_exports, _groupByDays) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _groupByDays.default;
    }
  });
  Object.defineProperty(_exports, "groupByDays", {
    enumerable: true,
    get: function get() {
      return _groupByDays.groupByDays;
    }
  });
});