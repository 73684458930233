define("dancecloud-dancer-app/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+UA7BMUr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"jumbotron\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"An error occurred\"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"lead\"],[12],[2,\"\\n      We're sorry but it looks like something has gone wrong.\\n    \"],[13],[2,\"\\n    \"],[10,\"hr\"],[14,0,\"m-y-2\"],[12],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      Try reloading the page. If the problem persists, please contact our\\n      \"],[10,\"a\"],[14,6,\"/support\"],[12],[2,\"Support Desk\"],[13],[2,\".\\n\\n\"],[6,[37,0],[[32,0,[\"lastEventId\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        Please quote reference \"],[10,\"strong\"],[12],[1,[32,0,[\"lastEventId\"]]],[13],[2,\" when you contact us.\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"lead\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Home Page\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "dancecloud-dancer-app/pods/error/template.hbs"
  });

  _exports.default = _default;
});