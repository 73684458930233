define("dancecloud-dancer-app/pods/join/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2uA1ksPm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"splash-layout\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"splash-header\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,6,\"/dc\"],[14,0,\"brand-logo-wrapper light\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"brand-logo\"],[12],[2,\"DanceCloud\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      Join DanceCloud as an organiser to create dance events and sell tickets -\\n      with fully automated lead/follow balancing if needed.\\n    \"],[13],[2,\"\\n    \"],[10,\"a\"],[14,6,\"/dc\"],[14,0,\"btn btn-secondary hidden-sm-down\"],[12],[2,\"Learn More\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"section\"],[14,0,\"splash-content\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "moduleName": "dancecloud-dancer-app/pods/join/template.hbs"
  });

  _exports.default = _default;
});