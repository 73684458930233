define("dancecloud-dancer-app/components/ui-ticket-assigned", ["exports", "ui-dancecloud-ember/components/ui-ticket-assigned"], function (_exports, _uiTicketAssigned) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiTicketAssigned.default;
    }
  });
});