define("dancecloud-dancer-app/validations/organiser", ["exports", "ember-changeset-validations/validators", "ui-dancecloud-ember/validators/chain", "dancecloud-dancer-app/validators/unique-organiser", "dancecloud-dancer-app/config/environment"], function (_exports, _validators, _chain, _uniqueOrganiser, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @TODO
   * When service injection is supported, it would be
   * better if the unique email validator used a service
   * to check the email - then it could be stubbed out
   * in integration tests, and the endpoints mocked
   * in acceptance tests.
   */
  var TESTING = 'test' === _environment.default.environment;
  var _default = {
    currency: (0, _chain.default)([(0, _validators.validatePresence)({
      presence: true,
      message: 'Please select a country.'
    }), (0, _validators.validateInclusion)({
      list: ['gbp', 'eur']
    })]),
    timeZone: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Please select a country.'
    }), (0, _validators.validateInclusion)({
      list: ['Europe/Dublin', 'Europe/London']
    })],
    account: (0, _chain.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3,
      max: 50
    }), (0, _validators.validateFormat)({
      regex: /^[a-z]{1}[a-z0-9-]+$/,
      message: '{description} must start with a letter and contain only lower case letters and numbers.'
    }), TESTING ? null : (0, _uniqueOrganiser.default)()].filter(Boolean)),
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    title: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3,
      max: 140
    })],
    website: (0, _chain.default)([(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^https?:\/\//,
      message: '{description} must start with http:// or https://'
    }), (0, _validators.validateFormat)({
      type: 'url'
    })])
  };
  _exports.default = _default;
});