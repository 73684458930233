define("dancecloud-dancer-app/pods/components/layout-account/form-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d809Bk/F",
    "block": "{\"symbols\":[\"Form\",\"el\",\"el\",\"el\",\"@account\"],\"statements\":[[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[30,[36,0],[[32,5],[32,0,[\"validations\"]]],null],[32,0,[\"handleSubmit\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"  \"],[8,[32,1,[\"element\"]],[],[[\"@label\",\"@property\"],[\"First Name\",\"firstName\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,4,[\"control\"]],[[24,\"placeholder\",\"First Name\"],[24,3,\"first-name\"],[24,\"autocomplete\",\"given-name\"]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n\"],[2,\"  \"],[8,[32,1,[\"element\"]],[],[[\"@label\",\"@property\"],[\"Surname\",\"surname\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"control\"]],[[24,\"placeholder\",\"Surname\"],[24,3,\"surname\"],[24,\"autocomplete\",\"family-name\"]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n\"],[2,\"  \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\"],[\"email\",\"Email\",\"email\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"control\"]],[[24,\"placeholder\",\"Email\"],[24,3,\"email\"],[24,\"autocomplete\",\"email\"]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[8,[32,1,[\"submitButton\"]],[],[[\"@defaultText\",\"@fulfilledText\",\"@pendingText\",\"@rejectedText\",\"@type\"],[\"Save\",\"Saved!\",\"Saving...\",\"Error\",\"primary\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"changeset\"]}",
    "moduleName": "dancecloud-dancer-app/pods/components/layout-account/form-profile/template.hbs"
  });

  _exports.default = _default;
});