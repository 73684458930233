define("dancecloud-dancer-app/helpers/currency-value", ["exports", "ember-dancecloud/helpers/currency-value"], function (_exports, _currencyValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "currencyValue", {
    enumerable: true,
    get: function get() {
      return _currencyValue.currencyValue;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _currencyValue.default;
    }
  });
});