define("dancecloud-dancer-app/pods/my/verify/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VTT5UQgP",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[8,\"layout-verify-email\",[],[[\"@user\"],[[32,1]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "dancecloud-dancer-app/pods/my/verify/template.hbs"
  });

  _exports.default = _default;
});