define("dancecloud-dancer-app/pods/components/table-orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iODCTFt7",
    "block": "{\"symbols\":[\"order\",\"@orders\"],\"statements\":[[10,\"div\"],[14,0,\"order-list list-group\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"table-orders/row\",[],[[\"@order\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row list-group-item\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-muted\"],[12],[2,\"No orders to display\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "dancecloud-dancer-app/pods/components/table-orders/template.hbs"
  });

  _exports.default = _default;
});