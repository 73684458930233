define("dancecloud-dancer-app/pods/join/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MbuM1lP+",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,0],[[32,0,[\"completed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"card-header\"],[12],[2,\"Account Created!\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-block\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        Good news, \"],[10,\"strong\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\" now has a DanceCloud account!\\n        You can manage the account at\\n        \"],[10,\"strong\"],[12],[1,[32,1,[\"account\"]]],[2,\".dancecloud.com/manage\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"a\"],[15,6,[31,[\"/organisers/\",[32,1,[\"account\"]],\"/manage\"]]],[14,0,\"btn btn-success\"],[12],[2,\"Go To Account\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"card-header\"],[12],[2,\"Organiser Registration\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-block\"],[12],[2,\"\\n\\n      \"],[8,\"form-organiser\",[],[[\"@onCancel\",\"@onSubmit\",\"@organiser\"],[[32,0,[\"cancel\"]],[32,0,[\"register\"]],[32,1]]],null],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "dancecloud-dancer-app/pods/join/details/template.hbs"
  });

  _exports.default = _default;
});