define("dancecloud-dancer-app/utils/features", ["exports", "ember-dancecloud/utils/features"], function (_exports, _features) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_features).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _features[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _features[key];
      }
    });
  });
});