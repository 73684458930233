define("dancecloud-dancer-app/pods/my/organisers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HxqetTDM",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[30,[36,0],[\"Organisers\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[8,\"layout-organisers\",[],[[\"@organisers\"],[[32,1]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "moduleName": "dancecloud-dancer-app/pods/my/organisers/template.hbs"
  });

  _exports.default = _default;
});