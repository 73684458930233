define("dancecloud-dancer-app/pods/auth/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nHW6LCeu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"card-header\"],[12],[2,\"Login\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-block\"],[12],[2,\"\\n    \"],[8,\"ui-form-login\",[],[[\"@onSubmit\"],[[30,[36,0],[\"authenticate\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-footer\"],[12],[2,\"\\n    Don't have a DanceCloud account?\"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"auth.register\"]],[[\"default\"],[{\"statements\":[[2,\"Register Now\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "moduleName": "dancecloud-dancer-app/pods/auth/login/template.hbs"
  });

  _exports.default = _default;
});