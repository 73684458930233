define("dancecloud-dancer-app/pods/components/layout-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gduKcFtx",
    "block": "{\"symbols\":[\"@account\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"Account\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-8\"],[12],[2,\"\\n\\n\"],[2,\"      \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"card-header\"],[12],[2,\"Profile\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n          \"],[8,\"layout-account/form-profile\",[],[[\"@account\",\"@onSubmit\"],[[32,1],[32,0,[\"saveProfile\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[2,\"      \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"card-header\"],[12],[2,\"Password\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"changingPassword\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"layout-account/form-password\",[],[[\"@onSubmit\"],[[32,0,[\"changePassword\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[8,\"bs-button\",[],[[\"@defaultText\",\"@onClick\",\"@type\"],[\"Change Password\",[32,0,[\"willChangePassword\"]],\"warning\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "dancecloud-dancer-app/pods/components/layout-account/template.hbs"
  });

  _exports.default = _default;
});