define("dancecloud-dancer-app/pods/my/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+YtT6WxJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container text-center\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-2x fa-circle-o-notch fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "dancecloud-dancer-app/pods/my/loading/template.hbs"
  });

  _exports.default = _default;
});