define("dancecloud-dancer-app/validations/user-account", ["exports", "ui-dancecloud-ember/validations/user", "ember-dancecloud/validators/unique-email", "dancecloud-dancer-app/config/environment"], function (_exports, _user, _uniqueEmail, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @TODO
   * When service injection is supported, it would be
   * better if the unique email validator used a service
   * to check the email - then it could be stubbed out
   * in integration tests, and the endpoints mocked
   * in acceptance tests.
   */
  var TESTING = 'test' === _environment.default.environment;

  var _default = (0, _user.default)({
    uniqueEmail: TESTING ? null : (0, _uniqueEmail.default)(),
    withPassword: false
  });

  _exports.default = _default;
});