define("dancecloud-dancer-app/services/sanctum", ["exports", "ember-dancecloud/services/sanctum"], function (_exports, _sanctum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sanctum.default;
    }
  });
});