define("dancecloud-dancer-app/pods/my/orders/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E37Q4YbH",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"Orders\"],[13],[2,\"\\n\\n  \"],[8,\"table-orders\",[],[[\"@orders\"],[[30,[36,0],[\"completedAt:desc\",[32,1]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sort-by\"]}",
    "moduleName": "dancecloud-dancer-app/pods/my/orders/index/template.hbs"
  });

  _exports.default = _default;
});