define("dancecloud-dancer-app/pods/components/table-payments/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fNt+d948",
    "block": "{\"symbols\":[\"@index\",\"@payment\"],\"statements\":[[10,\"div\"],[14,0,\"list-group-item\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-1 event-heading\"],[14,\"data-title\",\"#\"],[12],[1,[30,[36,0],[[32,1]],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"Card Payment\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[1,[30,[36,1],[[32,2,[\"paidAt\"]],\"llll\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-2\"],[12],[1,[30,[36,3],[[30,[36,2],[[32,2,[\"status\"]],\"succeeded\"],null]],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-2\"],[12],[1,[30,[36,4],[[32,2,[\"currency\"]],[32,2,[\"amount\"]]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inc\",\"moment-format\",\"or\",\"capitalize\",\"currency-value\"]}",
    "moduleName": "dancecloud-dancer-app/pods/components/table-payments/row/template.hbs"
  });

  _exports.default = _default;
});