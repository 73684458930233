define("dancecloud-dancer-app/helpers/percent-value", ["exports", "ui-dancecloud-ember/helpers/percent-value"], function (_exports, _percentValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _percentValue.default;
    }
  });
  Object.defineProperty(_exports, "percentValue", {
    enumerable: true,
    get: function get() {
      return _percentValue.percentValue;
    }
  });
});