define("dancecloud-dancer-app/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C9Mt9HHO",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"DanceCloud\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"ember-bootstrap-wormhole\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "moduleName": "dancecloud-dancer-app/pods/application/template.hbs"
  });

  _exports.default = _default;
});