define("dancecloud-dancer-app/pods/components/layout-account/form-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+QFCOlZI",
    "block": "{\"symbols\":[\"Form\"],\"statements\":[[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[30,[36,0],[[32,0],[32,0,[\"validations\"]]],null],[32,0,[\"handleSubmit\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],null,[[\"form\",\"new\"],[[32,1],true]]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[8,[32,1,[\"submitButton\"]],[],[[\"@defaultText\",\"@pendingText\",\"@type\"],[\"Change Password\",\"Changing Password...\",\"primary\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"changeset\",\"ui-input-password\"]}",
    "moduleName": "dancecloud-dancer-app/pods/components/layout-account/form-password/template.hbs"
  });

  _exports.default = _default;
});