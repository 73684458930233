define("dancecloud-dancer-app/components/ui-schedule-activity-choices", ["exports", "ui-dancecloud-ember/components/ui-schedule-activity-choices"], function (_exports, _uiScheduleActivityChoices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiScheduleActivityChoices.default;
    }
  });
});