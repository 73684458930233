define("dancecloud-dancer-app/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.hasClass('modal-login-or-register'), this.toValue(true), this.use('toRight'), this.reverse('toLeft'));
  }
});