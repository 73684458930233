define("dancecloud-dancer-app/components/ui-ticket-choice", ["exports", "ui-dancecloud-ember/components/ui-ticket-choice"], function (_exports, _uiTicketChoice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiTicketChoice.default;
    }
  });
});