define("dancecloud-dancer-app/helpers/is-user", ["exports", "ember-dancecloud/helpers/is-user"], function (_exports, _isUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isUser.default;
    }
  });
  Object.defineProperty(_exports, "isUser", {
    enumerable: true,
    get: function get() {
      return _isUser.isUser;
    }
  });
});