define("dancecloud-dancer-app/pods/auth/register/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZwDurL/m",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"card-header\"],[12],[2,\"Register Account\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-block\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"validator\",\"onSubmit\"],[[32,0,[\"validator\"]],[32,0,[\"register\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-footer\"],[12],[2,\"\\n    Already have a DanceCloud account?\"],[10,\"br\"],[12],[13],[2,\"\\n\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"auth.login\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Login here.\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"ui-form-register\"]}",
    "moduleName": "dancecloud-dancer-app/pods/auth/register/template.hbs"
  });

  _exports.default = _default;
});