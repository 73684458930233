define("dancecloud-dancer-app/pods/components/table-availability/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h8Gw+kjC",
    "block": "{\"symbols\":[\"@item\",\"@timeZone\"],\"statements\":[[10,\"div\"],[14,0,\"list-group-item\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-4 event-heading\"],[12],[2,\"\\n      \"],[10,\"strong\"],[12],[1,[32,1,[\"activity\",\"fullTitle\"]]],[13],[2,\"\\n      \"],[10,\"p\"],[12],[10,\"small\"],[12],[1,[30,[36,0],[[32,1,[\"activity\",\"startsAt\"]],\"llll z\"],[[\"timeZone\"],[[32,2]]]]],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-8\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n          \"],[8,\"ui-badge\",[],[[\"@type\"],[[32,1,[\"availability\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-2\"],[14,\"data-title\",\"Leads\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[32,1,[\"leads\"]],[32,1,[\"leads\"]],\"--\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-2\"],[14,\"data-title\",\"Follows\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[32,1,[\"follows\"]],[32,1,[\"follows\"]],\"--\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-2\"],[14,\"data-title\",\"Switch\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[32,1,[\"switch\"]],[32,1,[\"switch\"]],\"--\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-2\"],[14,\"data-title\",\"Dancers\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[32,1,[\"solo\"]],[32,1,[\"solo\"]],\"--\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"if\"]}",
    "moduleName": "dancecloud-dancer-app/pods/components/table-availability/row/template.hbs"
  });

  _exports.default = _default;
});