define("dancecloud-dancer-app/components/ui-brand-logo", ["exports", "ui-dancecloud-ember/components/ui-brand-logo"], function (_exports, _uiBrandLogo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiBrandLogo.default;
    }
  });
});